import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './App.scss';
import CookieConsent from './shared/cookie_consent/cookie_consent'
const Nav = lazy(() => import('./shared/nav/nav'));
const Footer = lazy(() => import('./shared/footer/footer'));
const Contact = lazy(() => import('./contact/contact'));
const Details = lazy(() => import('./contact/details'));
const Home = lazy(() => import('./home/home'));
const About = lazy(() => import('./about/about'));
const Services = lazy(() => import('./services/services'));
const Newsletters = lazy(() => import('./newsletter/newsletters'));
const Newsletter = lazy(() => import('./newsletter/newsletter'));
const Privacy = lazy(() => import('./legal/privacy'));
const Cookies = lazy(() => import('./legal/cookies'));
const PageNotFound = lazy(() => import('./shared/page_not_found'));

class App extends React.Component {
  render() {
    return (
			<Router>
				<CookieConsent/>
				<Suspense fallback={<div>Loading...</div>}>
					<Nav/>
					<div className='body'>
						<Switch>
							<Route exact path="/" component={Home}/>
							<Route exact path="/contact" component={Contact}/>
							<Route exact path="/contact/details" component={Details}/>
							<Route exact path="/about" component={About}/>
							<Route exact path="/services" component={Services}/>
							<Route exact path="/newsletters" component={Newsletters}/>
							<Route exact path="/newsletters/:id" component={Newsletter}/>
							<Route exact path="/privacy" component={Privacy}/>
							<Route exact path="/cookies" component={Cookies}/>
							<Route component={ PageNotFound } />
						</Switch>
					</div>
					<Footer/>
				</Suspense>
			</Router>
    );
  }
}

export default App;
