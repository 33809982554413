import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import { SquareButton } from '../button/button';

const privacy_policy_consent_styles = {
    position: 'fixed',
    bottom: 0,
    zIndex: 99,
    width: '80%',
    padding: "10px 10% 10px 10%",
    background: 'white',
    boxShadow: "rgba(0, 0, 0, 0.2) 0px -1px 2.5px"
};
const button_styles = {
    display: 'block',
    marginLeft: "auto",
    marginRight: "auto",
    color: 'white'
};

function CookieConsent() {

    const [ agree, setAgree ] = useState(false),
          [ show, setShow ] = useState(true),
          dataLayer = window.dataLayer || [],
          development = (process.env.NODE_ENV !== 'production');
    
    // Google analytics setup
    function gtag(){dataLayer.push(arguments);}
    
    
    useEffect(() => {
        // Set cookie to true if a cookie is found, sets agree to the cookie value and sets show to inverse of value
        if(Cookies.get('privacy_agreement')) {
            var alreadyAgreed = Cookies.getJSON('privacy_agreement').agree;
            setAgree(alreadyAgreed);
            setShow(!alreadyAgreed);
        }
    },[])
    return(<>
        {(agree && !development) ? gtag('js', new Date()) : null}
        {(agree && !development) ? gtag('config', 'UA-113990710-3') : null}
        {show && 
            <div
                style={privacy_policy_consent_styles}
                key='privacy_policy'
                className='cookie-consent'
                >
                <div>
                    <p>
                        We use cookies on our site, for more information see our <a href='/cookies'>cookies page</a> and our <a href='/privacy'>privacy policy</a>.
                    </p>
                    <SquareButton 
                        style={button_styles}
                        type='button'
                        onClick={() => {
                            Cookies.set('privacy_agreement', { agree: true }, {
                                expires: 365
                            })
                            setAgree(true);
                            setShow(false);
                        }}>
                        I'm ok with this
                    </SquareButton>
                </div>
            </div>
        }
    </>)
}

export default CookieConsent