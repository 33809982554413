import React from 'react';
import PropTypes from 'prop-types';
import { HashLink as Link } from 'react-router-hash-link';

import './button.scss'

export class Button extends React.Component {
    render() {
        const { children, styling, type, href, onClick, style, ...other } = this.props;
        var button;
        switch(type) {
            case 'submit':
                button = <input type={ type } value= { children } className = { styling } { ...other }/>
                break;
            case 'button':
                button = <button className = { styling } { ...other }>{ children }</button>
                break;
            case 'button-link':
                button = <Link to={ href } className = { "button-link " + styling } { ...other }>{ children }</Link>
                break;
            default:
                break;
        }
        return <div className='button' style={ style } onClick={onClick}>
            { button }
        </div>
    }
}

export const ColouredButton = (props) => {
    const { type, href, children, ...other } = props;
    return(
        <Button href={ href } type={ type } styling='colour-button' { ...other }>
            { children }
        </Button>
    )
}

export const SquareButton = (props) => {
    const { type, href, children, ...other } = props;
    return(
        <Button href={ href } type={ type } styling='square-button' {...other} >
            { children }
        </Button>
    )
}

Button.propTypes = {
    type: PropTypes.node.isRequired
};
ColouredButton.propTypes = {
    children: PropTypes.node.isRequired
};